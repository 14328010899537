import * as React from 'react';
import weScreenIt from '../images/we-screen-it.webp';
import weRateIt from '../images/we-rate-it.webp';
import weShareIt from '../images/we-share-it.webp';

const HowItWorksSection = () => {
    return (
        <div id="how-it-works" className="how-it-works">
            <div className="section-content">
                <h2 className="underlined">How it works?</h2>
                <div className="column-content">
                    <div className="column">
                        <img
                            className="image"
                            alt="The power of we"
                            src={weScreenIt}
                        />
                        <h5 className="title">We screen it</h5>
                        <div className="text-content">
                            Peers for Peers works as a publication radar.
                            Experts cover defined topics and perform searches
                            for new articles every month.
                        </div>
                    </div>
                    <div className="column">
                        <img
                            className="image"
                            alt="The power of we"
                            src={weRateIt}
                        />
                        <h5 className="title">We rate it</h5>
                        <div className="text-content">
                            The new feature of rating the clinical or scientific
                            relevance helps to easily find practice changer or
                            other influential articles. We need the signal, and
                            not the noise.
                        </div>
                    </div>
                    <div className="column">
                        <img
                            className="image"
                            alt="The power of we"
                            src={weShareIt}
                        />
                        <h5 className="title">We share it</h5>
                        <div className="text-content">
                            Neurosurgeons worldwide can log in and find the
                            selection of most impactful articles in their field.
                            Spend your time thinking, not searching.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorksSection;
