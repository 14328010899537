import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import eans from '../images/eans.svg';
import devices from '../images/devices.svg';
import { StaticImage } from 'gatsby-plugin-image';

function CoverSection() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [statistics, setStatistics] = useState([]);

    useEffect(() => {
        fetch(
            process.env.GATSBY_API_ENDPOINT +
                '/p4p/statistics/landing-statistic'
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setStatistics(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }, []);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else {
        return (
            <div className="cover-outline">
                <div className="cover">
                    <div className="section-content">
                        <div className="text">
                            <img alt="EANS logo" src={eans} />
                            <h1 className="underlined">Peers for Peers</h1>
                            <div className="subtitle">
                                The easiest way to stay current in neurosurgery.
                            </div>
                            <div className="buttons">
                                <Button
                                    onClick={() =>
                                        (window.location.href =
                                            process.env.GATSBY_P4P_APP_URL +
                                            '/sign-up')
                                    }
                                    className="button register-button"
                                    variant="contained"
                                >
                                    Register
                                </Button>
                                <Button
                                    onClick={() =>
                                        (window.location.href =
                                            process.env.GATSBY_P4P_APP_URL +
                                            '/sign-in')
                                    }
                                    className="button log-in-button"
                                    variant="outlined"
                                >
                                    Log In
                                </Button>
                            </div>
                        </div>
                        <div className="image">
                            <StaticImage
                                className="responsive-img"
                                alt="Devices"
                                loading="eager"
                                imgStyle={{
                                    transition: 'none'
                                }}
                                src="../images/devices.svg"
                                placeholder="none"
                            />
                        </div>
                    </div>
                </div>
                <div className="statistic">
                    <div className="statistic-info">
                        {isLoaded ? (
                            <div className="count">
                                {statistics.articlesScreened}
                            </div>
                        ) : (
                            <div className="animated-background"></div>
                        )}
                        <span className="text-relevant">Articles screened</span>
                    </div>
                    <div className="statistic-info">
                        {isLoaded ? (
                            <div className="count">
                                {isLoaded ? statistics.relevant : ''}
                            </div>
                        ) : (
                            <div className="animated-background"></div>
                        )}
                        <span className="text-relevant">
                            Selected by reviewers
                        </span>
                    </div>
                    <div className="statistic-info">
                        {isLoaded ? (
                            <div className="count">
                                {isLoaded ? statistics.influential : ''}
                            </div>
                        ) : (
                            <div className="animated-background"></div>
                        )}
                        <span className="text-relevant">Very influential</span>
                    </div>
                    <div className="statistic-info">
                        {isLoaded ? (
                            <div className="count">
                                {isLoaded ? statistics.practiceChanger : ''}
                            </div>
                        ) : (
                            <div className="animated-background"></div>
                        )}
                        <span className="text-relevant">
                            May change practice
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default CoverSection;
