import * as React from 'react';
import './doughnut-chart.scss';

const DoughnutChart = ({ percentage }) => {
    const doughnutSegmentStyles = {
        animation: 'doughnutopen 2s'
    };

    return (
        <div className="svg-doughnut">
            <style>{`
                @keyframes doughnutopen {
                    0% {
                        stroke-dasharray: 0, 100;
                    }
                    100% {
                        stroke-dasharray: ${percentage}, ${100 - percentage};
                    }
                }
            `}</style>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 40 40"
                className="doughnut"
            >
                <circle
                    className="doughnut-hole"
                    cx="20"
                    cy="20"
                    r="40"
                    fill="#fff"
                ></circle>
                <circle
                    className="doughnut-ring"
                    cx="20"
                    cy="20"
                    r="15.91549430918954"
                    fill="transparent"
                    strokeWidth="3"
                ></circle>
                <circle
                    className="doughnut-segment"
                    cx="20"
                    cy="20"
                    r="15.91549430918954"
                    fill="transparent"
                    strokeWidth="3"
                    strokeDasharray={percentage + ' ' + (100 - percentage)}
                    strokeDashoffset="25"
                    style={doughnutSegmentStyles}
                ></circle>
                <g className="doughnut-text">
                    <text y="50%" transform="translate(0, 2)">
                        <tspan
                            x="50%"
                            textAnchor="middle"
                            className="doughnut-percent"
                        >
                            {percentage}%
                        </tspan>
                    </text>
                </g>
            </svg>
        </div>
    );
};

export default DoughnutChart;
