import * as React from 'react';
import noNonsense from '../images/no-nonsense.svg';
import saveTime from '../images/save-time.svg';
import thePowerOfWe from '../images/the-power-of-we.svg';

const WhyPeersForPeersSection = () => {
    return (
        <div id="why-peers-for-peers" className="why-peers-for-peers">
            <div className="section-content">
                <h2 className="underlined">Why Peers for Peers?</h2>
                <div className="column-content">
                    <div className="column">
                        <img alt="No-Nonsense" src={noNonsense} />
                        <h5>No-Nonsense articles</h5>
                        <div className="text-content">
                            Stay focused on the most influential research with
                            Peers for Peers relevance scoring.
                        </div>
                    </div>
                    <div className="column">
                        <img alt="Save time" src={saveTime} />

                        <h5>Save time</h5>
                        <div className="text-content">
                            Quickly filter the most important publications based
                            on your specialty and custom fields of interest.
                        </div>
                    </div>
                    <div className="column">
                        <img alt="The power of we" src={thePowerOfWe} />
                        <h5>The power of we, for free</h5>
                        <div className="text-content">
                            Working together as a peer network, our goal is to
                            empower our neurosurgical community with a relevance
                            filter in a world of publication overload.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyPeersForPeersSection;
