import * as React from 'react';
import radar from '../images/radar.mp4';

const IntroSection = () => {
    return (
        <div className="intro">
            <div className="section-content">
                <div className="text">
                    <h2 className="underlined">
                        Keep up with articles that have a real impact in your
                        field.
                    </h2>
                    <div className="text-content">
                        <p>
                            Scholarly publishing has a problem. It produces tons
                            of articles with unclear content and quality. With
                            more and more publishers and journals, it becomes
                            even more difficult to determine what's relevant,
                            and what's not.
                        </p>
                        <p>
                            Peersforpeers.org was founded by physicians to help
                            their peers find the most impactful articles:
                            practice changers, educational highlights or other
                            publications that contribute to the future textbook
                            knowledge in their field.
                        </p>
                    </div>
                </div>
                <div className="image">
                    <video
                        loop
                        muted
                        playsInline
                        autoPlay={true}
                        className="responsive-img"
                        alt="Radar"
                        src={radar}
                    ></video>
                </div>
            </div>
        </div>
    );
};

export default IntroSection;
