import React, { useEffect } from 'react';
import lifelongLearning from '../images/lifelong-learning.svg';
import tooManyArticles from '../images/too-many-articles.svg';
import notEnoughTime from '../images/not-enough-time.svg';
import unclearImpact from '../images/unclear-impact.svg';
import DoughnutChart from '../components/doughnut-chart';
import { SelectedGraph } from '../models/selected-graph.enum';

const TheProblemSection = () => {
    var graphDelaySwitch = 6000;

    const [state, setState] = React.useState({
        selectedGraph: SelectedGraph.LIFELONG_LEARNING
    });

    const selectGraph = (value) => {
        setState({ selectedGraph: value });
        state.selectedGraph = value;
    };

    useEffect(() => {
        const id = window.setInterval(() => {
            changeGraph();
        }, graphDelaySwitch);
        return () => window.clearInterval(id);
    }, [state]);

    /**
     * Switches states of graph in circles.
     */
    const changeGraph = () => {
        if (state.selectedGraph === SelectedGraph.LIFELONG_LEARNING) {
            selectGraph(SelectedGraph.TOO_MANY_ARTICLES);
        } else if (state.selectedGraph === SelectedGraph.TOO_MANY_ARTICLES) {
            selectGraph(SelectedGraph.NOT_ENOUGH_TIME);
        } else if (state.selectedGraph === SelectedGraph.NOT_ENOUGH_TIME) {
            selectGraph(SelectedGraph.UNCLEAR_IMPACT);
        } else if (state.selectedGraph === SelectedGraph.UNCLEAR_IMPACT) {
            selectGraph(SelectedGraph.LIFELONG_LEARNING);
        }
    };

    return (
        <div id="the-problem" className="the-problem">
            <div className="section-content">
                <h2 className="underlined">The problem</h2>
                <div className="content-container">
                    <div className="graph-buttons">
                        <div
                            onClick={() =>
                                selectGraph(SelectedGraph.LIFELONG_LEARNING)
                            }
                            className="graph-button"
                        >
                            {state.selectedGraph !==
                            SelectedGraph.LIFELONG_LEARNING ? (
                                <div className="circle">92%</div>
                            ) : (
                                <div className="image">
                                    <img
                                        alt="Lifelong learning"
                                        src={lifelongLearning}
                                    />
                                </div>
                            )}
                            <div
                                className={`option-title ${
                                    state.selectedGraph ===
                                    SelectedGraph.LIFELONG_LEARNING
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                Lifelong learning
                            </div>
                        </div>
                        <div
                            onClick={() =>
                                selectGraph(SelectedGraph.TOO_MANY_ARTICLES)
                            }
                            className="graph-button"
                        >
                            {state.selectedGraph !==
                            SelectedGraph.TOO_MANY_ARTICLES ? (
                                <div className="circle">64%</div>
                            ) : (
                                <div className="image">
                                    <img
                                        alt="Too many articles"
                                        src={tooManyArticles}
                                    />
                                </div>
                            )}
                            <div
                                className={`option-title ${
                                    state.selectedGraph ===
                                    SelectedGraph.TOO_MANY_ARTICLES
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                Too many articles
                            </div>
                        </div>
                        <div
                            onClick={() =>
                                selectGraph(SelectedGraph.NOT_ENOUGH_TIME)
                            }
                            className="graph-button"
                        >
                            {state.selectedGraph !==
                            SelectedGraph.NOT_ENOUGH_TIME ? (
                                <div className="circle">72%</div>
                            ) : (
                                <div className="image">
                                    <img
                                        alt="Not enough time"
                                        src={notEnoughTime}
                                    />
                                </div>
                            )}
                            <div
                                className={`option-title ${
                                    state.selectedGraph ===
                                    SelectedGraph.NOT_ENOUGH_TIME
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                Not enough time
                            </div>
                        </div>
                        <div
                            onClick={() =>
                                selectGraph(SelectedGraph.UNCLEAR_IMPACT)
                            }
                            className="graph-button"
                        >
                            {state.selectedGraph !==
                            SelectedGraph.UNCLEAR_IMPACT ? (
                                <div className="circle">63%</div>
                            ) : (
                                <div className="image">
                                    <img
                                        alt="Unclear impact"
                                        src={unclearImpact}
                                    />
                                </div>
                            )}
                            <div
                                className={`option-title ${
                                    state.selectedGraph ===
                                    SelectedGraph.UNCLEAR_IMPACT
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                Unclear impact
                            </div>
                        </div>
                    </div>
                    <div className="graph">
                        {state.selectedGraph ===
                            SelectedGraph.LIFELONG_LEARNING && (
                            <DoughnutChart
                                className="doughnut"
                                percentage={92}
                            ></DoughnutChart>
                        )}
                        {state.selectedGraph ===
                            SelectedGraph.TOO_MANY_ARTICLES && (
                            <DoughnutChart
                                className="doughnut"
                                percentage={64}
                            ></DoughnutChart>
                        )}
                        {state.selectedGraph ===
                            SelectedGraph.NOT_ENOUGH_TIME && (
                            <DoughnutChart
                                className="doughnut"
                                percentage={72}
                            ></DoughnutChart>
                        )}
                        {state.selectedGraph ===
                            SelectedGraph.UNCLEAR_IMPACT && (
                            <DoughnutChart
                                className="doughnut"
                                percentage={63}
                            ></DoughnutChart>
                        )}
                    </div>
                    <div className="graph-description">
                        {state.selectedGraph ===
                            SelectedGraph.LIFELONG_LEARNING && (
                            <div>
                                92% of physicians agree on the importance of
                                knowing about new publications that may change
                                clinical practice in their field.
                            </div>
                        )}
                        {state.selectedGraph ===
                            SelectedGraph.TOO_MANY_ARTICLES && (
                            <div>
                                64% say the large number of new publications
                                makes it unrealistic to screen them for truly
                                valuable information.​
                            </div>
                        )}
                        {state.selectedGraph ===
                            SelectedGraph.NOT_ENOUGH_TIME && (
                            <div>
                                72% of physicians admit they don't have enough
                                time to search for new influential articles.
                            </div>
                        )}
                        {state.selectedGraph ===
                            SelectedGraph.UNCLEAR_IMPACT && (
                            <div>
                                63% would find a rating of the clinical or
                                scientific relevance useful.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TheProblemSection;
