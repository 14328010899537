import * as React from 'react';
import ourStory from '../images/our-story.svg';
import whoWeAre from '../images/who-we-are.webp';

const AboutUsSection = () => {
    return (
        <div id="about-us" className="our-story">
            <div className="section-content">
                <div className="row">
                    <div className="text right-margin">
                        <h2 className="underlined">Our story</h2>
                        <div className="text-content">
                            <p>
                                Keeping pace with medical progress is essential
                                for any specialty. However, in recent years, we
                                are facing an unprecedented overload of
                                publications, and it's only getting worse.
                            </p>
                            <p>
                                Cutting through the noise and making true
                                progress visible despite publication overload -
                                that's how Peers for Peers was born. We believe
                                in implementing relevant clinical and scientific
                                discoveries in our practice before they enter
                                the textbooks many years later.
                            </p>
                        </div>
                    </div>
                    <div className="image">
                        <img alt="The power of we" src={ourStory} />
                    </div>
                </div>
                <div className="row margin-top">
                    <div className="image right-margin">
                        <img alt="The power of we" src={whoWeAre} />
                    </div>
                    <h2 className="outer-h2 underlined">Who we are</h2>
                    <div className="text">
                        <h2 className="underlined">Who we are</h2>
                        <div className="text-content">
                            <p>
                                Peers for Peers was founded by the University of
                                Bern and the European Association of
                                Neurosurgical Societies as a filter in the world
                                of publication overload.
                            </p>
                            <p>
                                Our network of experts is dedicated to helping
                                our colleagues stay current.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUsSection;
